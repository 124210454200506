


















import { Component, Vue } from 'vue-property-decorator';
import Preloader from './components/fractions/Preloader.vue';
import Header from './components/fractions/Header.vue';
import WelcomeTmp from './components/sections/WelcomeTmp.vue';
import Services from './components/sections/Services.vue';
import About from './components/sections/About.vue';
import Resume from './components/sections/Resume.vue';
import Works from './components/sections/Works.vue';
import Price from './components/sections/Price.vue';
import Hire from './components/sections/Hire.vue';
import Testimonial from './components/sections/Testimonial.vue';
import Blog from './components/sections/Blog.vue';
import Contact from './components/sections/Contact.vue';
import Footer2 from './components/sections/Footer2.vue';
import { loadScript } from './utils';

@Component({
  components: {
    Preloader,
    Header,
    WelcomeTmp,
    Services,
    About,
    Resume,
    Works,
    Price,
    Hire,
    Testimonial,
    Blog,
    Contact,
    Footer2,
  },
})

export default class App extends Vue {
  mounted():void {
    // These scripts must be loaded after DOM is ready.
    Vue.nextTick(() => {
      // Addo scripts
      loadScript('/assets/js/main.js');

      // Loading the recaptcha main script
      // loadScript(`${recaptchaConfig.api}${recaptchaConfig.htmlKey}`);
    });
  }
}
