





























export default {
  computed: {
    getThisYear() {
      return (new Date()).getUTCFullYear();
    }
  }
}
